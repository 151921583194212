import React from "react";
import { observer } from "mobx-react";
import { IMake } from "ms_data";
import {
  contents,
  InfiniteScrollContainer,
  spacing,
  borderRadius,
  Tag,
  Body2,
  More,
} from "ms-ui";
import styled from "styled-components";

export interface IMakeApplyCardList {
  list: IMake[];
  onIntersect: any;
  options: any;

  /**
   * 해당 알림받기 상세페이지로 이동한다.
   */
  onClickHandler: Function;

  /**
   * timeId를 입력받아 시간 text로 변환하여 반환한다.
   */
  getTime: (timeId: string) => string;

  /**
   * createdAt을 입력받아 YYYY-MM-DD만 반환한다.
   */
  getDate: (createdAt: string) => string;
  isLoading: boolean;
  cursorId?: number;
  isLast: boolean;
}

export const MakeApplyCardList = observer(
  ({
    list,
    onIntersect,
    options,
    onClickHandler,
    getTime,
    getDate,
    isLoading,
    cursorId,
    isLast,
  }: IMakeApplyCardList) => {
    return (
      <>
        <StDiv1>
          <StDiv2
            onIntersect={onIntersect}
            options={options}
            isLoading={isLoading}
            cursorId={cursorId}
            isLast={isLast}
          >
            <StDiv3>
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <StDiv4 onClick={() => onClickHandler(item)}>
                      <StDiv5>
                        <StDiv6>
                          <Tag
                            color={`primary`}
                            radius={`5`}
                            light={true}
                            msg={`탑승`}
                            width={`fit-content`}
                          />
                          <StDiv7>{item.startTitle}</StDiv7>
                        </StDiv6>
                        <StDiv8>
                          <Tag
                            color={`primary`}
                            radius={`5`}
                            light={true}
                            msg={`하차`}
                            width={`fit-content`}
                          />
                          <StDiv9>{item.endTitle}</StDiv9>
                        </StDiv8>
                        <StDiv10>
                          <Tag
                            color={`primary`}
                            radius={`5`}
                            light={true}
                            msg={`시간`}
                            width={`fit-content`}
                          />
                          <StDiv11>
                            <StDiv12>{getTime(item.timeId)}</StDiv12>
                            <StDiv13>도착</StDiv13>
                          </StDiv11>
                        </StDiv10>
                      </StDiv5>
                      <StDiv14>
                        <StDiv15>
                          <StDiv16>신청일 :</StDiv16>
                          <StDiv17>{getDate(item.createdAt)}</StDiv17>
                        </StDiv15>
                        <More color={"#4C4C4C"} />
                      </StDiv14>
                    </StDiv4>
                  </div>
                );
              })}
            </StDiv3>
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 10px;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled(InfiniteScrollContainer)`
  display: flex;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  flex-direction: column;
  border-radius: ${borderRadius[5].value};
  border: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv5 = styled.div`
  display: flex;
  padding: ${spacing[12].value} 0px;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv6 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv7 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv8 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv12 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv13 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv14 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv15 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv16 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv17 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;
