import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import BoardListStore from "../../store/BoardListStore";
import {
  Body1,
  Body3,
  MediaQuery,
  Subtitle4,
  contents,
  isArray,
  spacing,
} from "ms-ui";
import styled, { css } from "styled-components";
import { arrayEquals } from "src/func/utils";
import { useVisibilityChange } from "src/hooks/useVisibilityChange";
import { SkltBoardCard } from "../Sklt/SkltBoardCard";
import {
  RunnBoardListFilter,
  SchdBoardListFilter,
} from "src/interface/dto/Board";
import { RtApplyCardListTpl } from "./RtApplyCardListTpl";

interface IProps {
  activeTab: "b" | "c";
}

export const BoardList = observer(({ activeTab }: IProps) => {
  const [store] = useState(() => new BoardListStore<typeof activeTab>());
  const { list, filter } = store;

  const { isMounted } = useVisibilityChange();

  useEffect(() => {
    store.setType(activeTab);
  }, [activeTab]);

  const FILTER_TAB = useMemo(() => {
    if (activeTab === "b") return RUNN_TAB;
    else return SCHD_TAB;
  }, [activeTab]);

  const init = () => {
    store.setList([]);
    store.setCursorId(undefined);
    store.loadData(activeTab, filter, 10);
    window.scrollTo(0, 0);
  };

  /**
   * filter가 바뀔 때마다 초기화
   */
  useEffect(() => {
    init();
  }, [filter]);

  /**
   * activeTab이 바뀔 때마다 filter 초기화
   */
  useEffect(() => {
    if (!isMounted) return;

    if (filter !== undefined) {
      store.setFilter(undefined);
    } else {
      init();
    }
  }, [activeTab]);

  return (
    <>
      {/* 상단 고정 필터 */}
      <TopFixed>
        {FILTER_TAB.map((t, idx) => {
          return (
            <TabItem
              key={idx}
              $active={
                isArray(filter) && isArray(t.key)
                  ? arrayEquals(filter, t.key)
                  : t.key === filter
              }
              onClick={() => {
                store.setFilter(t.key);
              }}
            >
              {t.text}
            </TabItem>
          );
        })}
      </TopFixed>

      {/* 리스트 */}
      <StListLayout>
        {/* 스켈레톤 */}
        {list.length === 0 && store.isLoading && (
          <StList>
            <SkltBoardCard />
            <SkltBoardCard />
            <SkltBoardCard />
          </StList>
        )}

        {/* no content */}
        {list.length === 0 && !store.isLoading && (
          <StList>
            <NoContent>
              {activeTab === "b"
                ? "신청한 운행중인 출근길이 없습니다."
                : "신청한 운행예정 출근길이 없습니다."}
            </NoContent>
          </StList>
        )}

        {list.length > 0 && (
          <RtApplyCardListTpl store={store} activeTab={activeTab} />
        )}
      </StListLayout>
    </>
  );
});

const StList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  padding: ${spacing[16].value};
  height: 100%;
`;

const TopFixed = styled.div`
  position: fixed;
  top: calc(56px + 44px);
  width: 100%;
  ${MediaQuery};
  z-index: 10;
  background-color: ${contents.secondary.value};

  display: flex;
  > div {
    margin-left: ${spacing[8].value};
    margin-right: ${spacing[8].value};
  }

  box-shadow: 0 -1px var(--contents-tertiary) inset;
`;

const TabItem = styled.div<{ $active: boolean }>`
  cursor: pointer;
  padding: ${spacing[8].value} ${spacing[4].value};
  ${(p) =>
    p.$active
      ? css`
          ${Subtitle4}
        `
      : css`
          ${Body3}
          color: ${contents.accent.value};
        `}
`;

const StListLayout = styled.div`
  margin-top: 78px;
  position: relative;
  background-color: ${contents.secondary.value};
  height: calc(100dvh - 134px);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoContent = styled.div`
  ${Body1};
  color: ${contents.description.value};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100dvh - 134px);
`;

const RUNN_TAB: {
  text: keyof typeof RunnBoardListFilter;
  key: (typeof RunnBoardListFilter)[keyof typeof RunnBoardListFilter];
}[] = [
  { text: "전체", key: undefined },
  { text: "신청∙탑승", key: ["a", "b", "d", "f"] },
  { text: "취소∙중단", key: ["c", "f"] },
  { text: "대기", key: "e" },
];

const SCHD_TAB: {
  text: keyof typeof SchdBoardListFilter;
  key: (typeof SchdBoardListFilter)[keyof typeof SchdBoardListFilter];
}[] = [
  { text: "전체", key: undefined },
  { text: "신청∙예약완료", key: ["a", "b", "d"] },
  { text: "취소", key: "c" },
];
