import { Body2, Subtitle1, contents } from "ms-ui";
import Skeleton from "src/components/Skeleton";
import styled from "styled-components";

export const SkltBoardCard = () => {
  return (
    <StCardSklt>
      <StRtInfoSklt>
        <Skeleton
          style={{ width: "70px", height: "20px", borderRadius: "10px" }}
        />
        <Skeleton
          style={{ width: "60px", height: "20px", borderRadius: "10px" }}
        />
      </StRtInfoSklt>
      <StStopInfoSklt>
        <StStopInfoRowSklt>
          <Skeleton
            style={{ width: "50px", height: "20px", borderRadius: "10px" }}
          />
          <Skeleton
            style={{ width: "30%", height: "20px", borderRadius: "10px" }}
          />
        </StStopInfoRowSklt>
        <StStopInfoRowSklt>
          <Skeleton
            style={{ width: "50px", height: "20px", borderRadius: "10px" }}
          />
          <Skeleton
            style={{ width: "50%", height: "20px", borderRadius: "10px" }}
          />
        </StStopInfoRowSklt>
      </StStopInfoSklt>
      <StCardTimestampSklt>
        <Skeleton
          style={{ width: "60%", height: "20px", borderRadius: "10px" }}
        />
      </StCardTimestampSklt>
    </StCardSklt>
  );
};

const StCardSklt = styled.div`
  background-color: ${contents.secondary.value};
  display: flex;
  flex-direction: column;
  padding: 16px;

  box-shadow: 0 0 0 1px var(--contents-tertiary) inset;
  border-radius: 5px;
`;

const StRtInfoSklt = styled.div`
  ${Subtitle1}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StCardTimestampSklt = styled.div`
  ${Body2}
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${contents.description.value};
`;
const StStopInfoSklt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 12px;
  padding-bottom: 12px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;
const StStopInfoRowSklt = styled.div`
  ${Body2}
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .stop-alias {
    color: ${contents.subtitle.value};
    width: 80%;
  }
`;
