import { observer } from "mobx-react";
import { MediaQuery, Nav, TabGroup } from "ms-ui";

import { useURI } from "ms_router";
import { useEffect, useState } from "react";
import { BackHistory } from "src/func/url";
import styled from "styled-components";
import { BoardList } from "./views/BoardList";
import { MakeList } from "./views/MakeList";

const TabType = {
  b: "운행중",
  c: "운행예정",
  d: "알림받기",
} as const;

const TAB: {
  text: (typeof TabType)[keyof typeof TabType];
  key: keyof typeof TabType;
}[] = [
  { text: "운행중", key: "b" },
  { text: "운행예정", key: "c" },
  { text: "알림받기", key: "d" },
];

const Apply = () => {
  const { navigate, changeQuery, getQuery } = useURI();
  const q = getQuery<{ tab?: keyof typeof TabType }>();
  const [activeTab, setActiveTab] = useState<keyof typeof TabType>(
    q.tab ?? "b"
  );

  useEffect(() => {
    changeQuery({ tab: activeTab }, true);
    window.scrollTo(0, 0);
  }, [activeTab]);

  return (
    <>
      <Nav
        title="신청내역"
        left={{
          type: "back",
          onClick: () => BackHistory(navigate, "/"),
        }}
      />

      {/* 상단 탭 */}
      <TopFixed>
        <TabGroup
          tabs={TAB.map((t) => ({
            ...t,
            onClick: () => setActiveTab(t.key),
          }))}
          selectedKey={activeTab}
        />
      </TopFixed>

      {/* 운행중 | 운행예정  */}
      {(activeTab === "b" || activeTab === "c") && (
        <BoardList activeTab={activeTab} />
      )}

      {/* 알림받기 */}
      {activeTab === "d" && <MakeList />}
    </>
  );
};

export default observer(Apply);

const TopFixed = styled.div`
  position: fixed;
  top: 56px;
  width: 100%;
  ${MediaQuery};
  z-index: 10;
`;
