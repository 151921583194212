import { AxiosResponse } from "axios";
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { CommonStore } from "ms-ui";
import { IBoard, IRt, isNil } from "ms_data";
import { getErrorMsg } from "src/func/utils";
import {
  RunnBoardListFilter,
  SchdBoardListFilter,
} from "src/interface/dto/Board";
import { IBoardList } from "src/interface/response/Board/applyList";
import { get_board_list } from "src/repository/Board/mylist";

export interface IBoardInfo {
  board: IBoard & {
    startAlias: string;
    endAlias: string;
    startIdx: number;
    endIdx: number;
  };
  rt: IRt;
}

class BoardListStore<Type extends "b" | "c"> extends CommonStore {
  _list: IBoardInfo[] = [];
  _isLast: boolean = false;
  _cursorId: number | undefined = undefined;
  _filter: Type extends "b"
    ? (typeof RunnBoardListFilter)[keyof typeof RunnBoardListFilter]
    : (typeof SchdBoardListFilter)[keyof typeof SchdBoardListFilter] =
    undefined;

  _type: "b" | "c" | undefined;

  constructor() {
    super();
    makeObservable(this, {
      _list: observable,
      list: computed,
      setList: action,

      _type: observable,
      type: computed,
      setType: action,

      _isLast: observable,
      isLast: computed,

      _cursorId: observable,
      cursorId: computed,
      setCursorId: action,

      _filter: observable,
      filter: computed,
      setFilter: action,

      loadData: flow,
    });

    reaction(
      () => this._type,
      (curr, prev) => {
        if (toJS(prev) === undefined) return;
        if (toJS(curr) === toJS(prev)) return;
        if (toJS(curr) !== toJS(prev)) this._list = [];
      }
    );
  }

  get list() {
    return toJS(this._list);
  }

  setList(l: IBoardInfo[]) {
    this._list = l;
  }

  get type() {
    return toJS(this._type);
  }
  setType(t: "b" | "c" | undefined) {
    this._type = t;
  }

  get cursorId() {
    return toJS(this._cursorId);
  }
  setCursorId(cId: number | undefined) {
    this._cursorId = cId;
  }

  get filter() {
    return toJS(this._filter);
  }
  setFilter(
    f: Type extends "b"
      ? (typeof RunnBoardListFilter)[keyof typeof RunnBoardListFilter]
      : (typeof SchdBoardListFilter)[keyof typeof SchdBoardListFilter]
  ) {
    this._filter = f;
  }

  get isLast() {
    return toJS(this._isLast);
  }

  *loadData(
    type: Type,
    filter: Type extends "b"
      ? (typeof RunnBoardListFilter)[keyof typeof RunnBoardListFilter]
      : (typeof SchdBoardListFilter)[keyof typeof SchdBoardListFilter],
    limit: number
  ) {
    try {
      this._loading = true;
      const { req } = get_board_list({
        type,
        ...(!isNil(filter) && { filter: filter.toString() }),
        limit: `${limit}`,
        ...(!isNil(this.cursorId) && { cursorId: `${this.cursorId}` }),
      });
      const res: AxiosResponse<IBoardList> = yield req();

      if (isNil(this.cursorId)) {
        this._list = res.data.list ?? [];
      } else {
        this._list = [...toJS(this._list), ...(res.data.list ?? [])];
      }

      this._cursorId = res.data.list[res.data.list.length - 1]?.board.boardId;
      this._isLast = res.data.isLast;
    } catch (e: any) {
      this._alert = {
        msg: getErrorMsg(e),
        open: true,
        fn: () => {},
      };
    } finally {
      this._loading = false;
    }
  }
}

export default BoardListStore;
