import { observer } from "mobx-react";
import { RtApplyCardList } from "./RtApplyCardList";
import BoardListStore from "../../../store/BoardListStore";
import { useURI } from "ms_router";
import { IBoard, IRt, mFormat } from "ms_data";
import { channelIO } from "src/func/tracking/channel";

interface IRtApplyCardListTpl {
  activeTab: "b" | "c";
  store: BoardListStore<"b" | "c">;
}

export const RtApplyCardListTpl = observer(
  ({ activeTab, store }: IRtApplyCardListTpl) => {
    const { navigate } = useURI();

    const clickCard = (item: { board: IBoard; rt: IRt }) => {
      const { board, rt } = item;
      channelIO.event("goApplyDetail", { no: board.boardId, type: rt.rtCd });
      navigate(`/mypage/applydetail?boardId=${board.boardId}`);
    };

    /** boardCd와 rtCd별 title */
    const getStatus = (item: { board: IBoard; rtCd: "b" | "c" }) => {
      const { board, rtCd } = item;
      const today = mFormat("YYYY-MM-DD");
      let txt = "";
      if (rtCd === "b") {
        if (board.boardCd === "a") txt = "탑승신청";
        if (board.boardCd === "b") {
          txt = "탑승중";
          if (board.startDay > today) txt = "탑승예정";
          if (board.endDay < today) txt = "탑승종료";
        }
        if (board.boardCd === "c") txt = "탑승취소";
        if (board.boardCd === "d") txt = "결제대기";
        if (board.boardCd === "e") txt = "탑승대기";
        if (board.boardCd === "f") {
          txt = "탑승중";
          if (board.startDay > today) txt = "탑승예정";
          if (board.endDay < today) txt = "탑승중단";
        }
      } else {
        if (board.boardCd === "a") txt = "탑승신청";
        if (board.boardCd === "b") txt = "탑승확정";
        if (board.boardCd === "c") txt = "탑승취소";
        if (board.boardCd === "d") txt = "결제대기";
      }
      return txt;
    };

    /** 정류장 번호 앞에 0 붙이기 */
    const getIndex = (stopIdx: string | number) => {
      return String(stopIdx).length === 1 ? `0${stopIdx}` : `${stopIdx}`;
    };

    /** 탑승기간 노출 여부 */
    const hasPeriod = (board: IBoard, activeTab: "b" | "c") => {
      if (
        activeTab === "b" &&
        board.boardCd !== "e" &&
        board.startDay &&
        board.endDay
      ) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <div>
        <RtApplyCardList
          activeTab={activeTab}
          list={store.list}
          onIntersect={() => {
            store.loadData(activeTab, store.filter, 10);
          }}
          options={{
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
          }}
          getStatus={getStatus}
          onClickHandler={clickCard}
          getIndex={getIndex}
          color={activeTab === "b" ? "secondary" : "primary"}
          hasPeriod={hasPeriod}
          hasCreatedAt={activeTab === "c"}
          isLoading={store.isLoading}
          isLast={store.isLast}
          cursorId={store.cursorId}
        />
      </div>
    );
  }
);
