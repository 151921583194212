import styled, { keyframes } from "styled-components";

const shine = keyframes`
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 100vw;
  }
`;

const Skeleton = styled.div`
  &:empty {
    width: inherit;
    height: inherit;
    background-image: linear-gradient(90deg, transparent, #ffffff, transparent);
    background-size: 200% 100%;
    display: inline-block;
    background-repeat: no-repeat;
    animation: ${shine} 1.6s infinite linear;
    background-color: var(--colors-gray-100);
  }
`;

export default Skeleton;
