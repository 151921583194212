import React from "react";
import { observer } from "mobx-react";
import {
  contents,
  InfiniteScrollContainer,
  spacing,
  borderRadius,
  Tag,
  Subtitle1,
  Body2,
  More,
} from "ms-ui";
import styled from "styled-components";

export interface IRtApplyCardList {
  activeTab: "b" | "c";
  list: any[];
  onIntersect: any;
  options: any;
  onClickHandler: Function;
  getStatus: Function;
  getIndex: Function;
  color: "secondary" | "primary";
  hasPeriod: Function;
  hasCreatedAt: boolean;
  isLoading: boolean;
  cursorId?: number;
  isLast: boolean;
}

export const RtApplyCardList = observer(
  ({
    activeTab,
    list,
    onIntersect,
    options,
    onClickHandler,
    getStatus,
    getIndex,
    color,
    hasPeriod,
    hasCreatedAt,
    isLoading,
    cursorId,
    isLast,
  }: IRtApplyCardList) => {
    return (
      <>
        <StDiv1>
          <StDiv2
            onIntersect={onIntersect}
            options={options}
            gap={16}
            isLoading={isLoading}
            isLast={isLast}
            cursorId={cursorId}
          >
            <StDiv3>
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <StDiv4 onClick={() => onClickHandler(item)}>
                      <StDiv5>
                        <Tag
                          color={color}
                          radius={`15`}
                          light={false}
                          msg={getStatus(item)}
                          width={`fit-content`}
                        />
                        <StDiv6>
                          <StDiv7>S</StDiv7>
                          <StDiv8>{item.rt.rtId}</StDiv8>
                        </StDiv6>
                      </StDiv5>
                      <StDiv9>
                        <StDiv10>
                          <Tag
                            color={`primary`}
                            radius={`5`}
                            light={true}
                            msg={`탑승`}
                            width={`fit-content`}
                          />
                          <StDiv11>
                            <StDiv12>
                              <StDiv13>
                                {getIndex(item.board.startIdx + 1)}
                              </StDiv13>
                              <StDiv14>.</StDiv14>
                            </StDiv12>
                            <StDiv15>{item.board.startAlias}</StDiv15>
                          </StDiv11>
                        </StDiv10>
                        <StDiv16>
                          <Tag
                            color={`primary`}
                            radius={`5`}
                            light={true}
                            msg={`하차`}
                            width={`fit-content`}
                          />
                          <StDiv17>
                            <StDiv18>
                              <StDiv19>
                                {getIndex(item.board.endIdx + 1)}
                              </StDiv19>
                              <StDiv20>.</StDiv20>
                            </StDiv18>
                            <StDiv21>{item.board.endAlias}</StDiv21>
                          </StDiv17>
                        </StDiv16>
                      </StDiv9>
                      <StDiv22>
                        <StDiv23>
                          {hasPeriod(item.board, activeTab) && (
                            <StDiv24>
                              <StDiv25>탑승기간 :</StDiv25>
                              <StDiv26>
                                <StDiv27>{item.board.startDay}</StDiv27>
                                <StDiv28>~</StDiv28>
                                <StDiv29>{item.board.endDay}</StDiv29>
                              </StDiv26>
                            </StDiv24>
                          )}
                          {hasCreatedAt && (
                            <StDiv30>
                              <StDiv31>신청일 :</StDiv31>
                              <StDiv32>
                                {item.board.createdAt.split(" ")[0]}
                              </StDiv32>
                            </StDiv30>
                          )}
                        </StDiv23>
                        <More color={"#4C4C4C"} />
                      </StDiv22>
                    </StDiv4>
                  </div>
                );
              })}
            </StDiv3>
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 10px;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled(InfiniteScrollContainer)`
  display: flex;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  flex-direction: column;
  border-radius: ${borderRadius[5].value};
  border: 1px solid ${contents.tertiary.value};
  background: ${contents.secondary.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv5 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv6 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Subtitle1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv9 = styled.div`
  display: flex;
  padding: ${spacing[12].value} 0px;
  flex-direction: column;
  gap: ${spacing[4].value};
  width: 100%;
`;

const StDiv10 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv12 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv13 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv14 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv15 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv16 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv17 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv18 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv19 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv20 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv21 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv22 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv23 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv24 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv25 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv26 = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StDiv27 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv28 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv29 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv30 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[4].value};
  width: fit-content;
`;

const StDiv31 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv32 = styled.div`
  ${Body2};
  color: ${contents.description.value};
  width: fit-content;
`;
