import { observer } from "mobx-react";
import { MSHUTTLE_DATA, MSHUTTLE_PATH } from "src/constants/addr";
import { MakeApplyCardList } from "./MakeApplyCardList";
import MakeListStore from "../../../store/MakeListStore";
import { IMake } from "ms_data";
import { channelIO } from "src/func/tracking/channel";

interface IMakeAPplyCardListTpl {
  store: MakeListStore;
}

export const MakeApplyCardListTpl = observer(
  ({ store }: IMakeAPplyCardListTpl) => {
    const getTime = (timeId: string) => {
      return (
        MSHUTTLE_DATA.TIME_LIST.find((t) => t.timeId === timeId)?.timeSearch ||
        ""
      );
    };

    const getDate = (createdAt: string) => {
      return createdAt.split(" ")[0];
    };

    const clickCard = (make: IMake) => {
      channelIO.event("goCollectApply", { no: make.makeId, type: "d" });
      window.location.href = `${MSHUTTLE_PATH.make}/update?makeId=${make.makeId}`;
    };

    return (
      <MakeApplyCardList
        list={store.list}
        onIntersect={() => {
          store.loadData(10);
        }}
        options={{
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }}
        onClickHandler={clickCard}
        getTime={getTime}
        getDate={getDate}
        isLoading={store.isLoading}
        isLast={store.isLast}
        cursorId={store.cursorId}
      />
    );
  }
);
