import { AxiosResponse } from "axios";
import { action, computed, flow, makeObservable, observable, toJS } from "mobx";
import { IMake, isNil } from "ms_data";
import { getErrorMsg } from "src/func/utils";
import { IMakeList } from "src/interface/response/Board/applyList";

import { get_make_list } from "src/repository/Board/mylist";

class MakeListStore {
  _list: IMake[] = [];
  _isLast: boolean = false;
  _loading: boolean = false;
  _cursorId: number | undefined = undefined;

  _alert: {
    open: boolean;
    msg: string;
    fn: any;
  } = { open: false, msg: "", fn: () => {} };

  constructor() {
    makeObservable(this, {
      _list: observable,
      list: computed,

      _isLast: observable,
      isLast: computed,

      _loading: observable,
      isLoading: computed,

      _alert: observable,
      alert: computed,

      _cursorId: observable,
      cursorId: computed,
      setCursorId: action,

      loadData: flow,
    });
  }

  get list() {
    return toJS(this._list);
  }

  get cursorId() {
    return toJS(this._cursorId);
  }
  setCursorId(cId: number | undefined) {
    this._cursorId = cId;
  }

  get isLast() {
    return toJS(this._isLast);
  }

  get isLoading() {
    return toJS(this._loading);
  }

  get alert() {
    return toJS(this._alert);
  }

  *loadData(limit: number) {
    try {
      this._loading = true;
      const { req } = get_make_list({
        limit: `${limit}`,
        ...(!isNil(this.cursorId) && { cursorId: `${this.cursorId}` }),
      });
      const res: AxiosResponse<IMakeList> = yield req();
      console.log("Res: ", res);

      if (isNil(this.cursorId)) {
        this._list = res.data.list ?? [];
      } else {
        this._list = [...this.list, ...(res.data.list ?? [])];
      }

      this._cursorId = res.data.list[res.data.list.length - 1]?.makeId;
      this._isLast = res.data.isLast;
    } catch (e: any) {
      this._alert = {
        msg: getErrorMsg(e),
        open: true,
        fn: () => {},
      };
    } finally {
      this._loading = false;
    }
  }
}

export default MakeListStore;
