import { useEffect, useState } from "react";

export const useVisibilityChange = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(true);

  /**
   * safari를 제외한, chrome과 firefox는 새로고침 시에도 visibilityChange 이벤트가 발생됨
   * 고로 isMount를 이용해 첫 마운트 시점을 잡아내야함.
   */
  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleVisible = () => {
    if (document.visibilityState === "visible") {
      console.log("visible");
      setVisible(true);
    }

    if (document.visibilityState === "hidden") {
      console.log("hidden");
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      handleVisible();
    });

    return () => {
      document.removeEventListener("visibilitychange", () => {
        handleVisible();
      });
    };
  }, []);

  return { visible, isMounted };
};
