import { Body2, contents } from "ms-ui";
import Skeleton from "src/components/Skeleton";
import styled from "styled-components";

export const SkltMakeCard = () => {
  return (
    <StCardSklt>
      <StInfoSklt>
        <StInfoRowSklt>
          <Skeleton
            style={{ width: "40px", height: "20px", borderRadius: "10px" }}
          />
          <Skeleton
            style={{ width: "55%", height: "20px", borderRadius: "10px" }}
          />
        </StInfoRowSklt>
        <StInfoRowSklt>
          <Skeleton
            style={{ width: "40px", height: "20px", borderRadius: "10px" }}
          />
          <Skeleton
            style={{ width: "35%", height: "20px", borderRadius: "10px" }}
          />
        </StInfoRowSklt>
        <StInfoRowSklt>
          <Skeleton
            style={{ width: "40px", height: "20px", borderRadius: "10px" }}
          />
          <Skeleton
            style={{ width: "45%", height: "20px", borderRadius: "10px" }}
          />
        </StInfoRowSklt>
      </StInfoSklt>
      <StCardTimestampSklt>
        <Skeleton
          style={{ width: "30%", height: "20px", borderRadius: "10px" }}
        />
      </StCardTimestampSklt>
    </StCardSklt>
  );
};

const StCardSklt = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${contents.secondary.value};
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px var(--contents-tertiary) inset;
`;

const StInfoSklt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  padding-top: 12px;
  padding-bottom: 12px;
`;

const StInfoRowSklt = styled.div`
  ${Body2}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .info {
    color: ${contents.subtitle.value};
    width: 80%;
  }
`;

const StCardTimestampSklt = styled.div`
  ${Body2}
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${contents.description.value};
`;
