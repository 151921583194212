/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetMyBoardList  } from './interface/res/get/Res.Get.MyBoardList';
import { ResGetMyMakeList  } from './interface/res/get/Res.Get.MyMakeList';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Mylist {

/**
 * **운행중|운행예정 신청 정보 리스트** (커서기반페이징으로 boardId 내림차순 정렬)  
신청∙탑승(a,b,d,f)의 경우 중단예정(boardCd = 'f' and endDay >= today)을 포함함,  
취소∙중단(c,f)의 경우 중단완료(boardCd = 'f' and endDay < today)를 포함함

*/
get_board_list(data: {   type: string,   cursorId?: string,   filter?: string,   limit: string }){
 return GET<ResGetMyBoardList>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.board_resource}`,
   url: `/mylist/board`,
 }, data);
}
/**
 * - 삭제하지 않은(confirmYn = 'y') **나의 알림받기 신청 리스트** (커서기반페이징으로 makeId 내림차순 정렬)

*/
get_make_list(data: {   cursorId?: string,   limit: string }){
 return GET<ResGetMyMakeList>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.board_resource}`,
   url: `/mylist/make`,
 }, data);
}
 
}

export const MylistRepository = new Mylist();
export const { get_board_list, get_make_list } = MylistRepository;
	