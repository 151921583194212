import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Body1, Button, More, contents, spacing } from "ms-ui";

import MakeListStore from "../../store/MakeListStore";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { SkltMakeCard } from "../Sklt/SkltMakeCard";
import { MakeApplyCardListTpl } from "./MakeApplyCardListTpl";

export const MakeList = observer(() => {
  const [store] = useState(() => new MakeListStore());
  const { list, isLoading } = store;

  useEffect(() => {
    store.loadData(10);
  }, []);

  return (
    <StListLayout style={{ padding: spacing[16].value }}>
      {list.length === 0 && isLoading && (
        <StList>
          <SkltMakeCard />
          <SkltMakeCard />
          <SkltMakeCard />
        </StList>
      )}

      {list.length === 0 && !isLoading && (
        <NoContent>
          <div>알림받기 내역이 없습니다.</div>

          <Button
            width="100%"
            onClick={() => {
              window.location.href = MSHUTTLE_PATH.make;
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div>내 출근길 알림받기</div>
              <More color="var(--contents-secondary)" />
            </div>
          </Button>
        </NoContent>
      )}

      {list.length > 0 && <MakeApplyCardListTpl store={store} />}
    </StListLayout>
  );
});

const StList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  padding: ${spacing[16].value};
  height: 100%;
`;

const StListLayout = styled.div`
  margin-top: ${spacing[44].value};
  position: relative;
  background-color: ${contents.secondary.value};
  height: calc(100dvh - 100px);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  width: 100%;
`;

const NoContent = styled.div`
  ${Body1};
  color: ${contents.description.value};

  display: flex;
  gap: ${spacing[8].value};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100dvh - 100px);
`;
